import { useFormContext } from 'react-hook-form';

import { useValidationStepData } from '../../validationStepContext/useValidationStepData/useValidationStepData';
import { useMutation, usePartnerConfig } from '@percent/hosted-validation-form/hooks';
import {
  validationSubmissionAction,
  ValidationSubmissionArguments,
} from '@percent/hosted-validation-form/api/actions/validationSubmissionAction';
import { HostedFormStep } from '../../HostedForm.types';
import { FormField } from '@percent/hosted-validation-form/app/HostedForm/HostedForm.types';
import { useHostedValidationsFormAnalytics } from '@percent/hosted-validation-form/hooks/useHostedValidationsFormAnalytics/useHostedValidationsFormAnalytics';

import { Terms } from './Terms';

const HELPER_FIELDS = ['withoutWebsite', 'differentEmail', 'unknownRegistryName', 'file'];

const submitBodyFactory = (
  formValues: Record<string, string | object>,
  country: string,
  partnerFields?: FormField[],
) => {
  const body = { ...formValues };

  if (formValues.partnerFields && partnerFields) {
    Object.keys(formValues.partnerFields).forEach((key) => {
      const currentField = partnerFields.filter((el) => el.id === key)[0];
      if (currentField && currentField.type === 'multiple_choice' && currentField.options) {
        currentField.options.forEach(({ key }) => {
          // eslint-disable-next-line no-prototype-builtins
          if (body.partnerFields.hasOwnProperty(key)) {
            delete body.partnerFields[key as keyof object];
          }
        });
      }

      const fieldValue = formValues.partnerFields[key as keyof object];
      if (
        [undefined, null, ''].includes(fieldValue) ||
        (Array.isArray(fieldValue) && (fieldValue as Array<unknown>).length === 0)
      ) {
        delete body.partnerFields[key as keyof object];
      }
    });
  }

  if (!Object.keys(body?.partnerFields ?? {}).length) {
    delete body.partnerFields;
  }

  Object.keys(formValues).forEach((key) => {
    if (!formValues[key]) {
      delete body[key];
    }
  });

  if (body.withoutWebsite) {
    delete body.website;
  }

  if (body.organisationId && !body.registryName) {
    delete body.countryCode;
  } else {
    body.countryCode = country;
  }

  if (body.registryName) {
    delete body.organisationId;
  }

  HELPER_FIELDS.forEach((field) => {
    if (body[field]) {
      delete body[field];
    }
  });

  return body;
};

export const TermsContainer = () => {
  const { handleNext, country } = useValidationStepData();
  const { getValues } = useFormContext();
  const { partnerConfig } = usePartnerConfig();
  const { track } = useHostedValidationsFormAnalytics();
  const { mutateAsync, isLoading, isError, error } = useMutation('validationSubmission', validationSubmissionAction, {
    onSuccess: () => {
      handleNext(HostedFormStep.COMPLETE);
      track('Validation Session Completed', {
        sessionResult: partnerConfig?.redirectUrl ? 'submitted_with_redirect' : 'submitted_thank_you',
      });
    },
    onError: () =>
      track('Validation Session Completed', {
        sessionResult: 'error',
      }),
  });

  const handleFormSubmit = async () => {
    const formValues = submitBodyFactory(getValues(), country, partnerConfig?.configuration.partnerFields);
    await mutateAsync(formValues as ValidationSubmissionArguments);
  };

  return <Terms handleFormSubmit={handleFormSubmit} isLoading={isLoading} isError={isError} error={error} />;
};
